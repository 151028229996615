import {
	browserTracingIntegration,
	replayIntegration,
	handleErrorWithSentry
} from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { getEnv } from '$lib/env';
import { dev, version } from '$app/environment';

// https://github.com/getsentry/sentry-javascript/issues/9252
async function initSentry() {
	if (dev) {
		await new Promise((resolve) => setTimeout(resolve, 5));
	}

	const environment = getEnv();

	Sentry.init({
		environment,
		release: version,
		dsn: 'https://013f4bab95bc4b308bc842745c8405fe@o4504119647207424.ingest.sentry.io/4505347488874496',
		tracesSampleRate: 0.5,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: environment === 'production' ? 0.1 : 0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [
			browserTracingIntegration({
				tracePropagationTargets: ['hml.orion.febrafar.com.br', 'orion.febrafar.com.br']
			}),
			replayIntegration({ maskAllText: false, blockAllMedia: false })
		],
		allowUrls: [/https:\/\/(www\.)?((hml|treinamento)\.)?orion\.febrafar\.com\.br/],
		ignoreErrors: [
			/AbortError:/i,
			/NotSupportedError:/i,
			/Failed to fetch/i,
			/An error occurred during playback/i,
			/null is not an object/i,
			/Unknown player. Probably unloaded/i,
			/Permission denied to access property/i,
			/Seu usuário não tem acesso ao produto Orion/i,
			/Cannot create property 'status' on string/i,
			/Não foi possível consultar o usuário logado atualmente/i,
			/An error occurred during playback/i,
			/Non-Error promise rejection captured with keys/i,
			// request.ts
			/Too many retried requests/i,
			/internal/i,
			/Load failed/i
		],
		beforeSend(event, hint) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const error = (hint.originalException || hint.syntheticException) as any;

			let mensagemErro = null;

			if (typeof error === 'string') {
				mensagemErro = error;
			} else if (error != null && typeof error?.message === 'string') {
				mensagemErro = error?.message;
			}

			if (mensagemErro == null) {
				return event;
			}

			const blacklistErrosComuns = [
				'failed to fetch dynamically imported module',
				'an error occurred during playback',
				'seu usuário não tem acesso ao produto orion',
				'usuário não encontrado',
				"can't access dead object",
				'auth/invalid-custom-token',
				'unknown player. probably unloaded',
				'installations/app-offline',
				'network error',
				"cannot create property 'status' on string",
				'auth/wrong-password',
				"cannot read properties of null (reading 'postmessage')",
				'internal',
				'load failed',
				'auth/network-request-failed',
				'auth/too-many-requests',
				'auth/user-token-expired',
				"cannot set properties of undefined (setting 'location')",
				"cannot create property 'status' on string 'status code: 400; bad request"
			];

			if (
				blacklistErrosComuns.some((erroComum) =>
					mensagemErro.toLowerCase().includes(erroComum.toLowerCase())
				)
			) {
				return null;
			}

			return event;
		}
	});
}

initSentry();

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
